import { useState, useRef, useCallback, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Configure } from 'react-instantsearch-dom';
import dynamic from 'next/dynamic';
import { getAlgoliaFacets } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';

const DropdownPerks = dynamic(() => import(`../DropdownPerks/DropdownPerks`));
const DropdownCompensation = dynamic(
  () => import(`../DropdownCompensation/DropdownCompensation`),
);
const Button = dynamic(() => import(`../Button/Button`));
const AlertModalComponent = dynamic(
  () => import(`../AlertModal/AlertModalComponent`),
);
const LocationDropdown = dynamic(
  () => import(`../LocationDropdown/LocationDropdown`),
);
const Autocomplete = dynamic(() => import(`../Autocomplete/Autocomplete`));

import {
  Container,
  Title,
  StyledDiv,
  DropdownsContainer,
  CheckboxDiv,
  StyledSearchDiv,
  ButtonWrapperDiv,
  Item,
  StyledTech,
  StyledTechDiv,
} from './SearchBarComponent.styles';
import { CrossIcon, MagnifierSVGIcon, NerdFaceIcon } from '@/icons';

import {
  aroundRadius,
  hitsPerPage,
  maxFacetHits,
} from '@/utils/algoliaVariables';

import { CategoryContext, Context, IsJobPageContext } from '@/utils/context';
import { ContextProps } from '@/utils/contextProps';
import {
  perksArray,
  compensationArray,
  MINIMUM_SALARY,
  MINIMUM_ANNUAL_SALARY,
} from '@/utils/constants';
import { indexName } from '../AppWrapper/AppWrapper';
import { Subtitle } from '@/blocks/CategoriesBlock/CategoriesBlock.styles';
import { SmallLoader } from '@/components/Loader/Loader.styles';

export interface perksStateInterface {
  visa: boolean;
  offers: boolean;
  startup: boolean;
  opensource: boolean;
  partTime: boolean;
}

export interface compensationStateInterface {
  // equity: boolean;
  // crypto: boolean;
  inputValue: string;
}

const searchClient = algoliasearch(
  process.env.ALGOLIA_APPLICATION_ID || ``,
  process.env.ALGOLIA_API_KEY || ``,
);
const index = searchClient.initIndex(indexName);

const NumberOfJobs = () => {
  const [numberOfJobs, setNumberOfJobs] = useState(0);

  useEffect(() => {
    index
      .search(``, {
        attributesToRetrieve: [],
        hitsPerPage: 0,
        facetFilters: [`deleted:false`],
      })
      .then((res) => setNumberOfJobs(res.nbHits));
  }, []);

  return <span>{numberOfJobs || <SmallLoader />}</span>;
};

const SearchBarComponent = () => {
  const isJobPage = useContext(IsJobPageContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isChecked, setIsChecked] = useState(!isJobPage);
  const [searchPlaceholder] = useState(`React, Python, Java...`);
  const {
    tags,
    setTags,
    perks,
    setPerks,
    resetPage,
    addQuery,
    removeQuery,
  }: ContextProps = useContext(Context);
  const { query } = useRouter() || {};
  const currentCategory = useContext(CategoryContext);
  const [perksState, setPerksState] = useState<perksStateInterface>({
    visa: false,
    offers: false,
    startup: false,
    opensource: false,
    partTime: false,
  });

  const [compensationState, setCompensationState] =
    useState<compensationStateInterface>({
      // equity: false,
      // crypto: false,
      inputValue: ``,
    });

  const remoteCheckboxHandler = () => {
    if (!isChecked) {
      removeQuery(`remote`, `false`);
    } else {
      addQuery(`remote`, `false`);
    }
    resetPage();
    setIsChecked((prevState) => !prevState);
  };

  const [location, setLocation] = useState(``);

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onButtonClickHandler = () => {
    const input = document.getElementsByClassName(
      `aa-Input`,
    )[0] as HTMLInputElement;
    if (input.value && !tags.includes(input.value.toLowerCase())) {
      resetPage();
      if (!currentCategory?.tags.includes(input.value.toLowerCase())) {
        addQuery(`tags`, input.value.toLowerCase());
      }
      setTags((prevState: string[]) => {
        return [...prevState, input.value.toLowerCase()];
      });
    }
  };

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ({ state }) => {
      if (state.query && !tags.includes(state.query.toLowerCase())) {
        resetPage();
        if (!currentCategory?.tags.includes(state.query.toLowerCase())) {
          addQuery(`tags`, state.query.toLowerCase());
        }
        setTags((prevState: string[]) => {
          return [...prevState, state.query.toLowerCase()];
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tags, setTags],
  );

  const changeCheckboxesState = (perk: string) => {
    perksArray.forEach((perkItem) => {
      if (perk === perkItem.perk) {
        setPerksState((prevState: any) => {
          return { ...prevState, [perkItem.field]: false };
        });
      }
    });
    compensationArray.forEach((compensationItem) => {
      if (perk === compensationItem.perk) {
        setCompensationState((prevState: any) => {
          return { ...prevState, [compensationItem.field]: false };
        });
      }
    });
  };

  const removeTagHandler = (type: string, value: string) => {
    switch (type) {
      case `tag`:
        resetPage();
        removeQuery(`tags`, value);
        return setTags((prevState: string[]) =>
          prevState.filter((state) => state !== value),
        );
      case `perk`:
        changeCheckboxesState(value);
        resetPage();
        removeQuery(`perks`, value.slice(6).toLowerCase());
        return setPerks((prevState: string[]) =>
          prevState.filter((state) => state !== value),
        );
      case MINIMUM_ANNUAL_SALARY:
        resetPage();
        removeQuery(MINIMUM_SALARY, `0`);
        setCompensationState((prevState: compensationStateInterface) => {
          return { ...prevState, inputValue: `` };
        });
        break;
      case `remote`:
        resetPage();
        addQuery(`remote`, `false`);
        setIsChecked(false);
    }
  };

  useEffect(() => {
    if (query.l) {
      setLocation(query.l.toString().slice(0, query.l.indexOf(` `)));
    }

    if (query.minimum_salary) {
      setCompensationState((prevState: any) => {
        return { ...prevState, inputValue: query.minimum_salary };
      });
    }
  }, [query]);

  const onItemSelectHandler = (item: string, refresh: () => void) => {
    if (!tags.includes(item)) {
      resetPage();
      if (!currentCategory?.tags.includes(item)) {
        addQuery(`tags`, item);
      }
      setTags((prevState: string[]) => {
        return [...prevState, item];
      });
      refresh();
    }
  };

  const getSourcesHandler = ({ query, refresh }: any) => [
    {
      sourceId: `_tags`,
      getItems() {
        return getAlgoliaFacets({
          searchClient,
          queries: [
            {
              indexName,
              facet: `_tags`,
              params: {
                facetQuery: query,
                maxFacetHits,
              },
            },
          ],
        });
      },
      templates: {
        item({ item }: any) {
          return <Item>{item.label}</Item>;
        },
      },
      onSelect({ item }: any) {
        onItemSelectHandler(item.label.toLowerCase(), refresh);
      },
    },
  ];

  useEffect(() => {
    setIsLoaded(true);
    const url = new URL(window.location.toString());
    const params = Object.fromEntries(url.searchParams.entries());
    if (params.remote) {
      setIsChecked(false);
    }
  }, []);

  if (!isLoaded) {
    return null;
  }

  const compensation = compensationState.inputValue;
  const numericFilters = compensation
    ? [`maxCompensation>=${compensation}`]
    : [];

  return (
    <Container>
      <Title>
        {currentCategory?.title
          ? currentCategory.title
          : `Looking for your next dream Job in Tech!`}
        <NerdFaceIcon />
      </Title>
      <Subtitle>
        {currentCategory?.subtitle ? (
          currentCategory.subtitle
        ) : (
          <div>
            Search Jobs in techs like React, Python or Java across&nbsp;
            <NumberOfJobs />
            &nbsp;jobs updated in real time&nbsp;
          </div>
        )}
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="/image-1.png"
          alt="laptop-icon"
          width="20px"
          height="20px"
          loading="lazy"
        />
      </Subtitle>

      <StyledDiv>
        {!isJobPage ? (
          location ? (
            <Configure
              query={``}
              facetFilters={[
                ...perks,
                `deleted:false`,
                isChecked ? `remote:true` : ``,
              ]}
              hitsPerPage={hitsPerPage}
              tagFilters={tags}
              attributesToRetrieve={[`*`, `-description`]}
              aroundLatLng={location}
              aroundRadius={aroundRadius}
              numericFilters={numericFilters}
            />
          ) : (
            <Configure
              query={``}
              facetFilters={[
                ...perks,
                `deleted:false`,
                isChecked ? `remote:true` : ``,
              ]}
              hitsPerPage={hitsPerPage}
              tagFilters={tags}
              attributesToRetrieve={[`*`, `-description`]}
              numericFilters={numericFilters}
            />
          )
        ) : null}
        <StyledSearchDiv>
          <MagnifierSVGIcon />
          <Autocomplete
            openOnFocus={true}
            onSubmit={onSubmit}
            detachedMediaQuery={false}
            getSources={getSourcesHandler}
            placeholder={searchPlaceholder}
          />
          <LocationDropdown inputRef={inputRef} setLocation={setLocation} />
          <ButtonWrapperDiv>
            <Button clickHandler={onButtonClickHandler}>Search a Job</Button>
          </ButtonWrapperDiv>
        </StyledSearchDiv>
        <DropdownsContainer>
          <DropdownPerks checkboxes={{ perksState, setPerksState }} />
          <DropdownCompensation
            checkboxes={{ compensationState, setCompensationState }}
          />
          <CheckboxDiv>
            <input
              type="checkbox"
              id="remote"
              onChange={remoteCheckboxHandler}
              checked={isChecked}
            />
            <label htmlFor="remote">Remote</label>
          </CheckboxDiv>
        </DropdownsContainer>
        <StyledTech>
          {tags.map((tag) => (
            <StyledTechDiv key={tag}>
              {tag}
              <button
                aria-label="close-btn"
                onClick={() => removeTagHandler(`tag`, tag)}
              >
                <CrossIcon />
              </button>
            </StyledTechDiv>
          ))}
          {perks.map((perk) => (
            <StyledTechDiv key={perk}>
              {perk.slice(6).replace(/_/g, ` `).toLowerCase()}
              <button
                aria-label="close-btn"
                onClick={() => removeTagHandler(`perk`, perk)}
              >
                <CrossIcon />
              </button>
            </StyledTechDiv>
          ))}
          {compensationState.inputValue && (
            <StyledTechDiv>
              minimum annual salary
              <button
                aria-label="close-btn"
                onClick={() => removeTagHandler(MINIMUM_ANNUAL_SALARY, ``)}
              >
                <CrossIcon />
              </button>
            </StyledTechDiv>
          )}
          {isChecked && (
            <StyledTechDiv key={`remote`}>
              remote
              <button
                aria-label="close-btn"
                onClick={() => removeTagHandler(`remote`, `remote`)}
              >
                <CrossIcon />
              </button>
            </StyledTechDiv>
          )}
          <AlertModalComponent
            searchState={{ tags, perks, isChecked, location }}
          />
        </StyledTech>
      </StyledDiv>
    </Container>
  );
};

export default SearchBarComponent;
