import styled from 'styled-components';
import { ThemeProps } from '@/styles/global';
import { StylesProps } from '@/utils/stylesProps';

type Props = ThemeProps & StylesProps;

export const Container = styled.div`
  position: relative;
  padding: 0 1 0.75rem 3.3125rem;
  background-color: ${(props: ThemeProps) => props.theme.backgroundColor};
  @media (min-width: 769px) and (max-width: 992px) {
    padding: 0 1.875rem 5rem;
  }
  p {
    margin: 0;
    text-align: center;
    color: ${(props: Props) => props.theme.blackColor};
  }
  @media (max-width: 768px) {
    padding: 0 1rem 1rem;
  }
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 1;
  padding-top: 2.125rem;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.625rem;
  margin: 0 0 2.8rem 0;
  color: ${(props: Props) => props.theme.blackColor};
  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding-top: 1rem;
    & svg {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
    }
  }
`;

export const StyledSearchDiv = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  background-color: ${(props: ThemeProps) => props.theme.whiteColor};
  border-radius: 0.25rem;
  height: 5.5rem;
  & > svg {
    margin: 2rem 0 2rem 2rem;
    z-index: 1;
  }
  @media (max-width: 768px) {
    height: auto;
    background-color: transparent;
    flex-direction: column;
    justify-content: space-between;
    & > svg {
      margin: 0.75rem 0 0.75rem 1rem;
      position: absolute;
      left: 0;
    }
  }
`;

export const ButtonWrapperDiv = styled.div`
  position: absolute;
  top: 1rem;
  bottom: 1rem;
  right: 1rem;
  width: 21.6%;
  max-width: 14.75rem;
  @media (max-width: 768px) {
    position: static;
    max-width: none;
    width: 100%;
    height: 3rem;
  }
`;

export const StyledDiv = styled.div`
  position: relative;
  z-index: 2;
  max-width: 68.75rem;
  margin: auto;
`;

export const DropdownsContainer = styled.div`
  position: relative;
  z-index: 2;
  margin-top: 0.75rem;
  display: flex;
  flex-wrap: wrap;
  min-width: 16.75rem;
  @media (max-width: 768px) {
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
`;

export const DropdownContainer = styled.div`
  width: 11.25rem;
  height: 2.75rem;
  box-shadow: 0 0 0.25rem rgba(16, 168, 35, 0.1);
  margin: 0.75rem 0.5rem 0 0;
  border-radius: 0.25rem;
  @media (max-width: 768px) {
    width: calc(50% - 0.5rem);
  }
  &:hover {
    box-shadow: 0 0 0.25rem rgba(16, 168, 35, 0.5);
  }
`;

export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding-left: 1.5rem;
  padding-right: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.whiteColor};
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 400;
  font-size: 1rem;
  color: ${(props: Props) => props.theme.blackColor};
  line-height: 1.5rem;
  border: 0.0625rem solid
    ${(props: Props) =>
      props.isOpen ? props.theme.softGreenColor : `transparent`};
  span {
    margin: 0 0.375rem 0 0.5rem;
    min-width: 1.625rem;
    min-height: 1.625rem;
    background: ${(props: Props) => props.theme.backgroundColor};
    border-radius: 0.25rem;
    font-weight: normal;
    font-size: 0.875rem;
    color: ${(props: Props) => props.theme.softGreenColor};
  }
  & svg:first-child {
    margin-right: 0.375rem;
  }
  & svg:last-child {
    margin-right: 1.5rem;
  }
  @media (max-width: 768px) {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    padding-left: 0.75rem;
    padding-right: 0;
    span {
      padding: 0.1875rem;
      font-size: 0.8125rem;
      margin: 0 0.5rem 0 auto;
    }
    & svg {
      margin-right: 0.375rem !important;
    }
  }
`;

export const DropdownMenu = styled.ul`
  display: ${(props: Props) => (props.isOpen ? `flex` : `none`)};
  flex-direction: column;
  padding-left: 0;
  justify-content: space-around;
  list-style: none;
  background-color: ${(props: Props) => props.theme.whiteColor};
  color: ${(props: ThemeProps) => props.theme.blackColor};
  width: 13.5rem;
  box-shadow: 0 0 0.25rem rgba(89, 115, 114, 0.16);
  border-radius: 0.25rem;
  font-weight: 300;
  li {
    margin: 0.5rem 1.5rem;
    & button {
      padding: 0;
      text-align: start;
    }
  }
  li:first-child {
    padding-top: 0;
    margin-top: 1.5rem;
  }
  li:last-child {
    margin-bottom: 1.5rem;
  }
  label {
    font-size: 0.875rem;
    line-height: 1.3125rem;
    width: 100%;
    &::before {
      margin-left: 0.25rem;
    }
  }
  @media (max-width: 768px) {
    margin-top: 0.25rem;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    li {
      margin: 0.5rem 1rem;
      &:first-child {
        margin-top: 1rem;
      }
    }
  }
`;

export const ApplyButton = styled.button`
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: ${(props: Props) => props.theme.lightGreenColor};
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    text-shadow: 0 0 0.25rem rgba(16, 168, 35, 0.3);
  }
`;

export const StyledLi = styled.li`
  font-weight: 500;
  & label {
    display: flex;
    & svg {
      margin-right: 0.5rem;
    }
  }
`;

export const CheckboxDiv = styled.div`
  display: flex;
  align-items: center;
  width: 8.625rem;
  height: 2.75rem;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem rgba(16, 168, 35, 0.1);
  margin: 0.75rem 0.5rem 0 1rem;
  background-color: ${(props: Props) => props.theme.whiteColor};
  color: ${(props: ThemeProps) => props.theme.blackColor};
  label {
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    height: 100%;
  }
  input[type='checkbox'] + label::before {
    margin: 0 0.5rem 0 1.75rem;
  }
  @media (max-width: 768px) {
    width: calc(50% - 0.5rem);
    margin-left: 0;
    label {
      font-size: 0.875rem;
      line-height: 1.3125rem;
    }
    input[type='checkbox'] + label::before {
      margin: 0 0.5rem 0 1rem;
    }
  }
  &:hover {
    box-shadow: 0 0 0.25rem rgba(16, 168, 35, 0.5);
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const StyledTech = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 1rem auto 0;
  min-height: 3.75rem;
`;

export const StyledTechDiv = styled.div`
  display: flex;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background: #e0f4e3;
  border-radius: 0.25rem;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: ${(props: ThemeProps) => props.theme.softGreenColor};
  padding: 0.625rem 0.75rem;
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding: 0;
    margin-left: 0.625rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.25rem;
    &:hover {
      background-color: rgba(19, 181, 93, 0.13);
    }
  }
  @media (max-width: 768px) {
    line-height: 1.3125rem;
    button {
      svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }
  @media (max-width: 438px) {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;
