import styled from 'styled-components';
import { ThemeProps } from '@/styles/global';

export const Container = styled.div`
  background-color: ${(props: ThemeProps) => props.theme.backgroundColor};
`;

export const StyledDiv = styled.div`
  min-width: 18.75rem;
  background-color: ${(props: ThemeProps) => props.theme.backgroundColor};
  max-width: 68.75rem;
  padding: 0rem 3.75rem 1.875rem 3.75rem;
  margin: auto;
  box-sizing: content-box;
  @media (min-width: 769px) and (max-width: 992px) {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  @media (max-width: 768px) {
    padding: 1rem 1rem 2rem 1rem;
  }
`;

export const CategoriesContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Title = styled.h2`
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1rem 0;
  @media (max-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.75rem;
    padding-top: 1rem;
    align-items: flex-start;
    & svg {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 1.5rem;
    }
  }
`;

export const Subtitle = styled.h2`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 1.875rem 0;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 2.125rem;
  & span {
    font-weight: 700;
  }
  @media (max-width: 1090px) {
    display: block;
    font-size: 1.3rem;
  }
  @media (max-width: 768px) {
    max-width: 25rem;
    margin: 1rem auto 1.5rem !important;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    display: block;
    span:first-child {
      top: 0.375rem;
    }
  }
`;
